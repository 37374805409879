<template>
  <div class="free-plan">
    <texts class="free-plan-text">
      <i18n path="customblock.popup.freePlan.text">
        <a href="https://www.matrixflow.net/matrixflow/contact-basic-plan">
          {{ $t('customblock.popup.freePlan.link') }}
        </a>
      </i18n>
    </texts>
    <div class="free-plan-footer">
      <button-main
        :text="$t('serviceList.popup.freePlan.checkPlan')"
        type="emphasis"
        @click="checkPlan"
      />
      <button-main :text="$t('common.close')" type="sub" @click="close" />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    texts
  },
  data() {
    return {}
  },
  props: {},
  computed: {},
  methods: {
    checkPlan() {
      location.href = 'https://www.matrixflow.net/matrixflow/contact-basic-plan'
    },
    close() {
      this.$router.push({
        name: 'projectList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.free-plan {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(768) adjustVW(384);
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-text {
    white-space: pre-line;
  }
  &-footer {
    padding: $space-medium $space-medium 0 0;
    border-right: $border-sub;
    &-inner {
      margin: 0 0 $space-medium;
    }
  }
}
</style>
