<template>
  <div>
    <customblockList
      :customblockList="customblockList"
      :popup="popup"
      :deleteLoading="deleteLoading"
      :accountInfo="accountInfo"
      :waitPageLoading="waitPageLoading"
      @menu-click="menuClick"
      @show-customblock="showCustomblock"
      @new-customblock="newCustomBlock"
      @close-modal="closePopup"
      @delete-customblock="deleteCustomBlock"
      @goto-recipe="gotoRecipe"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customblockList from '@/components/templates/customblock-list.vue'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    customblockList
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.pageLoading = true
      await vm.fetchAccountInfo()
      if (!['basic', 'enterprise_lite'].includes(vm.accountInfo?.plan)) {
        vm.showPopup('freePlan')
        return
      }
      await vm.fetchCustomblocks()
      vm.pageLoading = false
    })
  },
  data() {
    return {
      popup: {
        showPopup: [],
        deleteBlock: {
          target: null
        }
      },
      deleteLoading: false,
      pageLoading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('customblock', ['customblockList', 'loading', 'submitting']),
    waitPageLoading() {
      return this.pageLoading || this.deleteLoading
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('customblock', ['fetchCustomblocks', 'downloadCustomblockLog']),
    menuClick(menu) {
      switch (menu.name) {
        case 'download':
          this.downloadCustomblockLog({
            customblockId: menu.checked[0]
          })
          break
        case 'edit':
          this.$router.push({
            name: 'customblockEdit',
            params: {
              id: menu.checked[0]
            }
          })
          break
        case 'delete':
          this.popup.deleteBlock = {
            targets: menu.checked.map((id) =>
              this.customblockList.find((item) => item.customblock_id === id)
            )
          }
          this.showPopup('delete')
          break
      }
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    newCustomBlock() {
      this.$router.push({
        name: 'customblockEdit'
      })
    },
    showCustomblock(item) {
      const id = item.customblock_id

      this.$router.push({
        name: 'customblockDetail',
        params: {
          id
        }
      })
    },
    async deleteCustomBlock(items) {
      this.deleteLoading = true
      for (const item of items) {
        const res = await this.$sendMessageAndReceive({
          action: 'deleteCustomBlock',
          customBlockId: item.customblock_id
        })
        if (res.status === 'error') {
          throw res
        }
      }
      await this.fetchCustomblocks()
      this.closePopup('delete')
      this.deleteLoading = false
    },
    async gotoRecipe({ id, accountId }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: id,
        recipe_account_id: accountId
      })

      const projectId = res.detail.projectId

      this.$router.push({
        name: 'recipeProjectDetail',
        params: {
          projectId,
          id: `${id}-${accountId}`
        }
      })
    }
  }
}
</script>
